// overriding bootstrap variables in _boostrap-override.scss
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");

$family-base: "proxima-nova", sans-serif;
$family-header: "Oswald", sans-serif;

// font-size
$font-size-desktop: 18px;
$font-size-mobile: 16px;

$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semi-bold: 600;
$weight-bold: 700;
$weight-extra-bold: 800;
$weight-normal: $weight-regular;

// colors - sister colours from bootrap
$color-blue: #00aeef;
$color-red: #c20f2f;
$color-orange: #fd7e14;
$color-green: #28a745;
$color-xpress-orange: #ff5900;
$color-xpress-gray: #333;
$color-xpress-light-orange: #f6823b;

// more colors
$color-gold: #faa222;
$color-dark-gray: #252525;
$color-gray: #505050;
$color-dark-blue: #222d64;
$color-mid-blue: #5c6594;

// site colors
$primary-color: $color-xpress-orange;
$font-color: black;
$font-color-white: #fff;
$header-color: $primary-color;
$bg-color: #fff;
$link-color: $primary-color;
$link-hover-color: $color-xpress-light-orange;

// top-bar
$top-bar-border: (
  mobile: 5px,
  desktop: 12px,
);
$top-bar-height: (
  mobile: 44px,
  small-device: 82px,
  tablet: 82px,
  desktop: 82px,
);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;
