.pageTiles {
  @include tile--titleDesc();
  margin: 2rem 0;
  grid-gap: 1rem;
  @media screen and (max-width: 576px) {
    // margin: 2rem 0;
    grid-gap: 2rem;
  }
  &.useSlider {
    display: block;
    .tile {
      max-width: 100%;
    }
  }
}

.contentPanel .pageTiles .tile {
  @media screen and (min-width: 992px) {
    flex: 0 0 32%;
    max-width: 32%;
  }
}

.pageTile__content {
  position: relative;
  &:hover,
  &:focus {
    text-decoration: none;
    .pageTile_details {
      transition: $transition-base;
    }
    // .small_tile {
    //   background-size: 170%;
    // }
    .pageTile__title,
    .pageTile_desc {
      color: #fff;
      transition: $transition-base;
    }
    .tile__bg {
      transform: scale(1.02);
      transition: $transition-base;
    }
    .pageTile_btn {
      background: transparent;
      border: 1px solid $primary-color;
      color: #fff;
      text-decoration: none;
    }
  }
}

.pageTile__title {
  font-family: $family-header;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1;
  margin: 0 0 0.8rem 0;
  color: #fff;
}

.pageTile_desc {
  line-height: 1.3;
  font-size: 1rem;
  color: #fff;
  margin: 0 0 1rem 0;
}

.pageTile_details {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  text-align: left;
  padding: 2rem 1.5rem;
  transition: $transition-base;
  background: linear-gradient(180deg, transparent 0%, #212121ed 38%);
  position: absolute;
  bottom: 0;
}

.contentPanel--dark {
  .pageTile_details:before {
    background-image: linear-gradient(
      to bottom left,
      rgba(#fff, 0.3),
      rgba(#fff, 0)
    );
  }

  .pageTile__title,
  .pageTile_desc {
    color: white;
  }
}

.pageTile_btn {
  color: white;
  background: $primary-color;
  padding: 0.2rem 1rem;
  border: 1px solid $primary-color;
  font-weight: $weight-medium;
  border-radius: 20px;
  display: inline-block;
}

// .small_tile {
//   background-size: 170%;
// }
.small_tile.pageTile_details {
  padding: 1.3rem 1.5rem;
  background: linear-gradient(
    180deg,
    transparent,
    rgba(33, 33, 33, 0.929412) 30%
  );
}
.small_tile .pageTile__title {
  font-size: 1.7rem;
  margin: 0 0 0.7rem;
}
.small_tile .pageTile_desc {
  font-size: 0.9rem;
  margin: 0 0 0.9rem;
}
.small_tile .pageTile_btn {
  font-size: 0.85rem;
}
