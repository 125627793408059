// logo
// .topHeader_style .masthead_2,
// .topHeader_style_scrolled .masthead_2,
// .topHeader_style .masthead_3,
// .topHeader_style_scrolled .masthead_3 {
//   display: none;
// }
.topHeader_style .masthead_2,
.topHeader_style_scrolled .masthead_2 {
  display: none;
}
.Masthead-Theme-1 .masthead_1 {
  display: block;
}
.Masthead-Theme-2 .masthead_1,
.Masthead-Theme-3 .masthead_1,
.Masthead-Theme-4 .masthead_1 {
  display: none;
}
.Masthead-Theme-2 .masthead_2,
.Masthead-Theme-3 .masthead_3,
.Masthead-Theme-4 .masthead_3 {
  display: block;
}
.Masthead-Theme-3 .masthead_3,
.Masthead-Theme-4 .masthead_3 {
  display: flex;
  gap: 1rem;
}
.Masthead-Theme-3 .masthead_3.logo2,
.Masthead-Theme-4 .masthead_3.logo2 {
  margin-left: 1rem;
}
img.masthead_1 {
  display: none;
}
a.masthead_3 {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

// font color
// Masthead-Theme-1: color
h3 {
  color: $color-xpress-orange;
}
h1,
h2,
h4,
h5,
h6,
p,
li {
  color: #fff;
}
// Masthead-Theme-2: color
.Masthead-Theme-2 h1,
.Masthead-Theme-2 h2,
.Masthead-Theme-2 h4,
.Masthead-Theme-2 h5,
.Masthead-Theme-2 h6,
.Masthead-Theme-2 p,
.Masthead-Theme-2 li {
  color: $color-xpress-gray;
}
// Masthead-Theme-3: color
.Masthead-Theme-3 p,
.Masthead-Theme-3 li {
  color: #fff;
}
// Masthead-Theme-4: color
.Masthead-Theme-4 h1,
.Masthead-Theme-4 h2,
.Masthead-Theme-4 h4,
.Masthead-Theme-4 h5,
.Masthead-Theme-4 h6,
.Masthead-Theme-4 p,
.Masthead-Theme-4 li {
  color: #333;
}
