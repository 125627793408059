b,
strong {
  font-weight: $weight-bold;
}

a {
  color: $link-color;
  transition: $transition-base;
  &:hover,
  &:focus,
  &:active {
    color: $link-hover-color;
    text-decoration: none;
  }
}

// font family & font weight
h1,
h2,
h3 {
  font-family: $family-header;
  margin-bottom: 0;
  font-weight: $weight-medium;
}

h4,
h5,
h6 {
  font-family: $family-base;
  margin-bottom: 0;
  font-weight: $weight-normal;
}

// Masthead-Theme-1: color
h3 {
  color: $color-xpress-orange;
}
h1,
h2,
h4,
h5,
h6,
p {
  color: #fff;
}
// Masthead-Theme-2: color
.Masthead-Theme-2 h1,
.Masthead-Theme-2 h2,
.Masthead-Theme-2 h4,
.Masthead-Theme-2 h5,
.Masthead-Theme-2 h6,
.Masthead-Theme-2 p {
  color: $color-xpress-gray;
}

// size
h1 {
  font-size: rem(32, $font-size-mobile);
}
h2 {
  font-size: rem(24, $font-size-mobile);
}
h3 {
  font-size: rem(20, $font-size-mobile);
}
h4 {
  font-size: rem(20, $font-size-mobile);
}
h5 {
  font-size: rem(16, $font-size-mobile);
}
h6 {
  font-size: rem(12, $font-size-mobile);
}
@include media-breakpoint-up(lg) {
  h1 {
    font-size: rem(56, $font-size-desktop);
  }
  h2 {
    font-size: rem(48, $font-size-desktop);
  }
  h3 {
    font-size: rem(28, $font-size-desktop);
  }
  h4 {
    font-size: rem(24, $font-size-desktop);
  }
  h5 {
    font-size: rem(16, $font-size-desktop);
  }
  h6 {
    font-size: rem(12, $font-size-desktop);
  }
}

p,
li {
  font-weight: $weight-normal;
}

// html editor
.text-tiny {
  font-size: 0.75rem;
}
.text-big {
  font-size: 1.5rem;
}
p {
  margin-bottom: 0;
}
