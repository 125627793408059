/*
  <div class="tiles">
    <div class="tile">
      <div class="tile__content>
        <div class="tile__bg></div>
        <div class="tile__details></div>
      </div>
    </div>
  </div>
*/

@mixin tile--titleDesc(
  $breakpoints: (
    xs: 1,
    sm: 2,
    md: 2,
    lg: 3,
  ),
  $margin: 24px,
  $ratio: 120%
) {
  margin-left: -$margin/2;
  margin-right: -$margin/2;
  display: flex;
  justify-content: flex-start; //center;
  flex-wrap: wrap;

  &.center {
    justify-content: center;
  }

  .tile {
    // padding-right: $margin/2;

    @each $bp, $number in $breakpoints {
      @include media-breakpoint-up(#{$bp}) {
        flex: 0 0 100% / $number;
        max-width: 100% / $number;
      }
    }
  }
  .tile:last-child {
    padding-right: 0;
  }

  .tile__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .tile__bg {
    @include bg-img();
    @include img-ratio($ratio);
    transition: all 0.2s;
  }
}
