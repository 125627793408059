.banner-item {
  max-width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  &.bannerHasTitlePanel::before {
    content: "";
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
  }
}

.banner_item__text {
  text-align: center;
  padding: 0.5rem 0 0.7rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 1200px;
  z-index: 10;
  font-family: $family-base;
  font-weight: 600;
  color: #fff;
  font-size: 1.6rem;
  line-height: 1.1;

  @include media-breakpoint-up(lg) {
    font-size: 2.3rem;
    margin-bottom: -0.3rem;
  }
}

.banner-item__link {
  color: white;
  background: $primary-color;
  padding: 0.2rem 1rem;
  border: 1px solid $primary-color;
  font-weight: $weight-medium;
  border-radius: 20px;
  font-size: 1rem;
  &:hover,
  &:focus {
    color: white;
    background: transparent;
    border: 1px solid #fff;
    text-decoration: none;
  }
}

.banner-item__title {
  font-family: "Oswald", sans-serif;
  margin-bottom: 1.5rem;
}

.banner-item__desc {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.bannerItem__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
