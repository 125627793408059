.pageTiles__bsyc .pageTile.tile {
  flex: 0 0 49% !important;
  max-width: 49% !important;
  height: inherit;
  @media screen and (max-width: 576px) {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
.pageTiles__bsyc .tile__bg {
  padding-top: 120%;
}
